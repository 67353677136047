/* TODO APP*/
export const WATCH_TODO_LIST = "WATCH_TODO_LIST";
export const GET_TODO_LIST = "GET_TODO_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* ECOMMERRCE APP */
export const GET_LIST = "GET_LIST";
export const WATCH_PRODUCT_LIST = "WATCH_PRODUCT_LIST";
export const WATCH_SINGLE_ITEM = "WATCH_SINGLE_ITEM";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const SEARCH_MEMBER_PRODUCT = "SEARCH_MEMBER_PRODUCT";

/* Bookmark App */
export const WATCH_BOOKMARK_LIST = "WATCH_BOOKMARK_LIST";
export const GET_BOOKMARK_LIST = "GET_BOOKMARK_LIST";
export const ADD_TO_MY_BOOKMARK = "ADD_TO_MY_BOOKMARK";
export const ADD_NEW_BOOKMARK = "ADD_NEW_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const REMOVE_FROM_MY_BOOKMARK = "REMOVE_FROM_MY_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";
export const UPDATE_MY_BOOKMARK = "UPDATE_MY_BOOKMARK";

/* Task App */
export const WATCH_TASK_LIST = "WATCH_TASK_LIST";
export const FETCH_ALL_TASK = "FETCH_ALL_TASK";
export const NEW_TASK = "NEW_TASK";
export const REMOVE_TASK = "REMOVE_TASK";

// Cart

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";
export const SEARCH_BY = "SEARCH_BY";

/* CHAT APP*/
export const GET_MEMBERS = "GET_MEMBERS";
export const WATCH_CHAT_MEMBERS = "WATCH_CHAT_MEMBERS";
export const WATCH_CHAT_SUCCESS = "WATCH_CHAT_SUCCESS";
export const WATCH_ALL_CHAT_SUCCESS = "WATCH_ALL_CHAT_SUCCESS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_WATCHER = "CREATE_CHAT_WATCHER";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_WATCHER = "SEND_MESSAGE_WATCHER";
export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER";
export const REPLY_MESSAGE_WATCHER = "REPLY_MESSAGE_WATCHER";

// EMAIL APP
export const WATCH_EMAIL = "WATCH_EMAIL";
export const WATCH_ALL_TYPE_EMAIL = "WATCH_ALL_TYPE_EMAIL";
export const GET_ALL_EMAIL_ASYN = "GET_ALL_EMAIL_ASYN";
export const GET_ALL_TYPE_ASYN = "GET_ALL_TYPE_ASYN";
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";

// Project
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";

// AUTHENTICATION APP
export const LOGIN = "LOGIN";
export const INITIALIZE_FIREBASE = "INITIALIZE_FIREBASE";

//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

// SHARE REGISTRAR ACTION TYPES
// COMPANIES
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_DROPDOWN = "GET_COMPANIES_DROPDOWN";
export const GET_COMPANIES_SYMBOLS = "GET_COMPANIES_SYMBOLS";
export const WATCH_COMPANIES = "WATCH_COMPANIES";
export const WATCH_COMPANIES_DROPDOWN = "WATCH_COMPANIES_DROPDOWN";
export const WATCH_COMPANIES_SYMBOLS = "WATCH_COMPANIES_SYMBOLS";
export const COMPANIES_SYMBOL_BEGIN_LOADING = "COMPANIES_SYMBOL_BEGIN_LOADING";
export const COMPANIES_SYMBOL_END_LOADING = "COMPANIES_SYMBOL_END_LOADING";
export const COMPANIES_BEGIN_LOADING = "COMPANIES_BEGIN_LOADING";
export const COMPANIES_BEGIN_DROPDOWN_LOADING =
  "COMPANIES_BEGIN_DROPDOWN_LOADING";

export const COMPANIES_END_LOADING = "COMPANIES_END_LOADING";
export const COMPANIES_END_DROPDOWN_LOADING = "COMPANIES_END_DROPDOWN_LOADING";

// SHARE HOLDERS
export const GET_SHAREHOLDERS = "GET_SHAREHOLDERS";
export const GET_INACTIVE_SHAREHOLDERS = "GET_INACTIVE_SHAREHOLDERS";
export const GET_SHAREHOLDERS_DROPDOWN = "GET_SHAREHOLDERS_DROPDOWN";
export const GET_INACTIVE_SHAREHOLDERS_DROPDOWN =
  "GET_INACTIVE_SHAREHOLDERS_DROPDOWN";
export const WATCH_SHAREHOLDERS = "WATCH_SHAREHOLDERS";
export const WATCH_INACTIVE_SHAREHOLDERS = "P";
export const WATCH_SHAREHOLDERS_DROPDOWN = "WATCH_SHAREHOLDERS_DROPDOWN";

export const SHAREHOLDERS_BEGIN_LOADING = "SHAREHOLDERS_BEGIN_LOADING";
export const INACTIVE_SHAREHOLDERS_BEGIN_LOADING =
  "INACTIVE_SHAREHOLDERS_BEGIN_LOADING";
export const INACTIVE_SHAREHOLDERS_END_LOADING =
  "INACTIVE_SHAREHOLDERS_END_LOADING";
export const SHAREHOLDERS_BEGIN_DROPDOWN_LOADING =
  "SHAREHOLDERS_BEGIN_DROPDOWN_LOADING";

export const SHAREHOLDERS_END_LOADING = "SHAREHOLDERS_END_LOADING";
export const SHAREHOLDERS_END_DROPDOWN_LOADING =
  "SHAREHOLDERS_END_DROPDOWN_LOADING";

// PHYSICAL SHARE HOLDERS
export const GET_PHYSICAL_SHAREHOLDERS = "GET_PHYSICAL_SHAREHOLDERS";
export const GET_PHYSICAL_SHAREHOLDERS_DROPDOWN =
  "GET_PHYSICAL_SHAREHOLDERS_DROPDOWN";
export const WATCH_PHYSICAL_SHAREHOLDERS = "WATCH_PHYSICAL_SHAREHOLDERS";
export const WATCH_PHYSICAL_SHAREHOLDERS_DROPDOWN =
  "WATCH_PHYSICAL_SHAREHOLDERS_DROPDOWN";
// ELECTRONIC SHARE HOLDERS
export const GET_ELECTRONIC_SHAREHOLDERS = "GET_ELECTRONIC_SHAREHOLDERS";
export const GET_ELECTRONIC_SHAREHOLDERS_DROPDOWN =
  "GET_ELECTRONIC_SHAREHOLDERS_DROPDOWN";
export const WATCH_ELECTRONIC_SHAREHOLDERS = "WATCH_ELECTRONIC_SHAREHOLDERS";
export const WATCH_ELECTRONIC_SHAREHOLDERS_DROPDOWN =
  "WATCH_ELECTRONIC_SHAREHOLDERS_DROPDOWN";
export const UBO_LOADING = "UBO_LOADING"
// INVESTORS
export const GET_INVESTORS = "GET_INVESTORS";
export const GET_INVESTORS_DROPDOWN = "GET_INVESTORS_DROPDOWN";
export const WATCH_INVESTORS = "WATCH_INVESTORS";
export const WATCH_INVESTORS_DROPDOWN = "WATCH_INVESTORS_DROPDOWN";
export const INVESTOR_BEGIN_LOADING = "INVESTOR_BEGIN_LOADING";
export const INVESTOR_BEGIN_DROPDOWN_LOADING =
  "INVESTOR_BEGIN_DROPDOWN_LOADING";

export const INVESTOR_END_LOADING = "INVESTOR_END_LOADING";
export const INVESTOR_END_DROPDOWN_LOADING = "INVESTOR_END_DROPDOWN_LOADING";

// INVESTOR REQUEST
export const GET_INVESTORS_REQUEST = "GET_INVESTORS_REQUEST";
export const GET_INVESTORS_REQUEST_DROPDOWN = "GET_INVESTORS_REQUEST_DROPDOWN";
export const WATCH_INVESTORS_REQUEST = "WATCH_INVESTORS_REQUEST";
export const WATCH_INVESTORS_REQUEST_DROPDOWN =
  "WATCH_INVESTORS_REQUEST_DROPDOWN";
export const GET_INVESTORS_REQUEST_TYPES = "GET_INVESTORS_REQUEST_TYPES";
export const WATCH_INVESTORS_REQUEST_TYPES = "WATCH_INVESTORS_REQUEST_TYPES";

export const INVESTOR_REQUEST_BEGIN_LOADING = "INVESTOR_REQUEST_BEGIN_LOADING";
export const INVESTOR_REQUEST_BEGIN_DROPDOWN_LOADING =
  "INVESTOR_REQUEST_BEGIN_DROPDOWN_LOADING";

export const INVESTOR_REQUEST_END_LOADING = "INVESTOR_REQUEST_END_LOADING";
export const INVESTOR_REQUEST_END_DROPDOWN_LOADING =
  "INVESTOR_REQUEST_END_DROPDOWN_LOADING";

// SHARE CERTIFICATES
export const GET_SHARE_CERTIFICATES = "GET_SHARE_CERTIFICATES";
export const GET_SHARE_CERTIFICATES_DROPDOWN =
  "GET_SHARE_CERTIFICATES_DROPDOWN";
export const WATCH_SHARE_CERTIFICATES = "WATCH_SHARE_CERTIFICATES";
export const WATCH_SHARE_CERTIFICATES_DROPDOWN =
  "WATCH_SHARE_CERTIFICATES_DROPDOWN";

export const SHARE_CERTIFICATES_BEGIN_LOADING =
  "SHARE_CERTIFICATES_BEGIN_LOADING";
export const SHARE_CERTIFICATES_BEGIN_DROPDOWN_LOADING =
  "SHARE_CERTIFICATES_BEGIN_DROPDOWN_LOADING";

export const SHARE_CERTIFICATES_END_LOADING = "SHARE_CERTIFICATES_END_LOADING";
export const SHARE_CERTIFICATES_END_DROPDOWN_LOADING =
  "SHARE_CERTIFICATES_END_DROPDOWN_LOADING";

// TRANSACTION REQUEST
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_LISTING = "GET_TRANSACTION_LISTING";
export const GET_TRANSACTION_REQUEST_DROPDOWN =
  "GET_TRANSACTION_REQUEST_DROPDOWN";
export const WATCH_TRANSACTION_REQUEST = "WATCH_TRANSACTION_REQUEST";
export const WATCH_TRANSACTION_LISTING = "WATCH_TRANSACTION_LISTING";
export const WATCH_TRANSACTION_REQUEST_DROPDOWN =
  "WATCH_TRANSACTION_REQUEST_DROPDOWN";

export const GET_TRANSACTION_REQUEST_TYPES = "GET_TRANSACTION_REQUEST_TYPES";
export const WATCH_TRANSACTION_REQUEST_TYPES =
  "WATCH_TRANSACTION_REQUEST_TYPES";

export const TRANSACTION_REQUEST_BEGIN_LOADING =
  "TRANSACTION_REQUEST_BEGIN_LOADING";
export const TRANSACTION_LISTING_BEGIN_LOADING =
  "TRANSACTION_LISTING_BEGIN_LOADING";
export const TRANSACTION_REQUEST_BEGIN_DROPDOWN_LOADING =
  "TRANSACTION_REQUEST_BEGIN_DROPDOWN_LOADING";

export const TRANSACTION_REQUEST_END_LOADING =
  "TRANSACTION_REQUEST_END_LOADING";
export const TRANSACTION_LISTING_END_LOADING =
  "TRANSACTION_LISTING_END_LOADING";
export const TRANSACTION_REQUEST_END_DROPDOWN_LOADING =
  "TRANSACTION_REQUEST_END_DROPDOWN_LOADING";

// ENTITLEMENTS
export const GET_ENTITLEMENTS = "GET_ENTITLEMENTS";
export const GET_ENTITLEMENTS_DROPDOWN = "GET_ENTITLEMENTS_DROPDOWN";
export const WATCH_ENTITLEMENTS = "WATCH_ENTITLEMENTS";
export const WATCH_ENTITLEMENTS_DROPDOWN = "WATCH_ENTITLEMENTS_DROPDOWN";

export const GET_ENTITLEMENTS_TYPES = "GET_ENTITLEMENTS_TYPES";
export const WATCH_ENTITLEMENTS_TYPES = "WATCH_ENTITLEMENTS_TYPES";

export const ENTITLEMENTS_BEGIN_LOADING = "ENTITLEMENTS_BEGIN_LOADING";
export const ENTITLEMENTS_BEGIN_DROPDOWN_LOADING =
  "ENTITLEMENTS_BEGIN_DROPDOWN_LOADING";

export const ENTITLEMENTS_END_LOADING = "ENTITLEMENTS_END_LOADING";
export const ENTITLEMENTS_END_DROPDOWN_LOADING =
  "ENTITLEMENTS_END_DROPDOWN_LOADING";

// ANNOUNCEMENTS
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_DROPDOWN = "GET_ANNOUNCEMENTS_DROPDOWN";
export const WATCH_ANNOUNCEMENTS = "WATCH_ANNOUNCEMENTS";
export const WATCH_ANNOUNCEMENTS_DROPDOWN = "WATCH_ANNOUNCEMENTS_DROPDOWN";

export const GET_ANNOUNCEMENTS_TYPES = "GET_ANNOUNCEMENTS_TYPES";
export const WATCH_ANNOUNCEMENTS_TYPES = "WATCH_ANNOUNCEMENTS_TYPES";

export const ANNOUNCEMENTS_BEGIN_LOADING = "ANNOUNCEMENTS_BEGIN_LOADING";
export const ANNOUNCEMENTS_BEGIN_DROPDOWN_LOADING =
  "ANNOUNCEMENTS_BEGIN_DROPDOWN_LOADING";

export const ANNOUNCEMENTS_END_LOADING = "ANNOUNCEMENTS_END_LOADING";
export const ANNOUNCEMENTS_END_DROPDOWN_LOADING =
  "ANNOUNCEMENTS_END_DROPDOWN_LOADING";

// ANNOUNCEMENTS
export const GET_DIVIDEND = "GET_DIVIDEND";
export const WATCH_DIVIDEND = "WATCH_DIVIDEND";

export const DIVIDEND_BEGIN_LOADING = "DIVIDEND_BEGIN_LOADING";

export const DIVIDEND_END_LOADING = "DIVIDEND_END_LOADING";

// USER FEATURES
export const GET_FEATURES = "GET_FEATURES";
export const WATCH_FEATURES = "WATCH_FEATURES";

// ROLES
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_DROPDOWN = "GET_ROLES_DROPDOWN";
export const GET_ROLES_SYMBOLS = "GET_ROLES_SYMBOLS";
export const WATCH_ROLES = "WATCH_ROLES";
export const WATCH_ROLES_DROPDOWN = "WATCH_ROLES_DROPDOWN";
export const ROLES_BEGIN_LOADING = "ROLES_BEGIN_LOADING";
export const ROLES_BEGIN_DROPDOWN_LOADING = "ROLES_BEGIN_DROPDOWN_LOADING";

export const ROLES_END_LOADING = "ROLES_END_LOADING";
export const ROLES_END_DROPDOWN_LOADING = "ROLES_END_DROPDOWN_LOADING";

// ELECTIONS
export const GET_ELECTIONS = "GET_ELECTIONS";
export const GET_ELECTIONS_DROPDOWN = "GET_ELECTIONS_DROPDOWN";
export const GET_ELECTIONS_SYMBOLS = "GET_ELECTIONS_SYMBOLS";
export const WATCH_ELECTIONS = "WATCH_ELECTIONS";
export const WATCH_ELECTIONS_DROPDOWN = "WATCH_ELECTIONS_DROPDOWN";
export const ELECTIONS_BEGIN_LOADING = "ELECTIONS_BEGIN_LOADING";
export const ELECTIONS_BEGIN_DROPDOWN_LOADING =
  "ELECTIONS_BEGIN_DROPDOWN_LOADING";

export const ELECTIONS_END_LOADING = "ELECTIONS_END_LOADING";
export const ELECTIONS_END_DROPDOWN_LOADING = "ELECTIONS_END_DROPDOWN_LOADING";
