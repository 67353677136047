import React, { Fragment, useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { LogOut } from "react-feather";
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutCentral, logoutMain } from "store/services/auth.service";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useSelector } from "react-redux";
// import Avatar from "../../../assets/images/avtar"

const UserMenu = ({ history }) => {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const [profile, setProfile] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  // auth0 profile
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const [screen, setScreen] = useState();

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL"));
  }, []);

  const logoutApp = async () => {
    const email = sessionStorage.getItem("email");
    try {
      const mainResponse = await logoutMain(email);
      const response = await logoutCentral(email);

      toast.success(response.data.message);
      // toast.error(mainResponse.data.message);
      // setTimeout(() => {
      handleLogout();
      // }, 1000)
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.replace(`${process.env.PUBLIC_URL}/login`);
  };

  const UserProfile = styled.div`
   .popup{margin-bottom:20px;
  overflow-x: scroll;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 5px;
    width: 3px;
    padding-bottom:20px
  }

  ::-webkit-scrollbar-track {
    background: #f9f9fb;
  }
  ::-webkit-scrollbar-thumb {
    background: #4e515680;
    border-radius: 5px;
  }
}
  .email_text{
  text-wrap: wrap !important
  }
  
  #a_tag{
  color:black !important}
  #a_Tag:hover{
  color:black !important
  }
  #a_Tag_email{
  color:black !important
  display: flex !important
  }
  #a_Tag_email:hover{
  color:black !important
  }

  `;

  const popUpStyle =
    mixLayout == "dark-only" || mixLayout == "dark-header-sidebar-mix"
      ? "#293240"
      : "#f6f7fb";
  const popUpTextStyle =
    mixLayout == "dark-only" || mixLayout == "dark-header-sidebar-mix"
      ? "hsla(0,0%,100%,.85)"
      : "black";

  const dividerStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "#eff0f1"
      : "#374558";

  const profilePopupStyle = {
    width: "250px",
    top: "63px",
    right: screen <= 575 ? "7px" : "-10px",
    left: "inherit",
    position: "absolute",
    backgroundColor: popUpStyle,
    // border: searchResultBorder,
    cursor: "default",
  };

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          {auth0_profile?.picture ? (
            <img
              // onMouseEnter={() => setShowProfile(true)}
              // onMouseLeave={() => setShowProfile(false)}
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              src={authenticated ? auth0_profile.picture : profile}
              alt="header-user"
            />
          ) : (
            <div
              // onMouseEnter={() => setShowProfile(true)}
              // onMouseLeave={() => setShowProfile(false)}
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            >
              <svg
                style={{ width: "100%", height: "25px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  fill={localStorage.getItem("primary_color")}
                  d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S5 22.086 5 16 9.914 5 16 5zm0 3c-2.75 0-5 2.25-5 5 0 1.516.707 2.863 1.781 3.781A7.005 7.005 0 009 23h2c0-2.773 2.227-5 5-5s5 2.227 5 5h2c0-2.7-1.531-5.05-3.781-6.219C20.293 15.863 21 14.516 21 13c0-2.75-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3z"
                ></path>
              </svg>
            </div>
          )}
          {/* <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div> */}
        </div>
        <UserProfile>
          <ul
            style={{ cursor: "default", padding: "20px 20px 10px 20px" }}
            className="profile-dropdown onhover-show-div profile-dropdown-hover"
          >
            <div className="popup">
              <li id="a_Tag">
                <a
                  id="a_Tag"
                  style={{
                    cursor: "default",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  href="#"
                >
                  {sessionStorage.getItem("name")?.toUpperCase()}
                </a>
              </li>
              <li id="a_Tag_email" className="pt-0 text-wrap">
                <a
                  id="a_Tag_email"
                  className="text-wrap"
                  style={{ cursor: "default" }}
                  href="#"
                >
                  {sessionStorage.getItem("email")?.toUpperCase()}
                </a>
              </li>
              <li id="a_Tag" className="py-0">
                <a id="a_Tag" style={{ cursor: "default" }} href="#">
                  {sessionStorage.getItem("role")?.toUpperCase()}
                </a>
              </li>

              <li style={{ paddingBottom: "10px" }} className="mt-2 ">
                <a
                  style={{ cursor: "pointer" }}
                  // onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}
                  onClick={logoutApp}
                  //   href="#javascript"
                >
                  <LogOut /> {"Log out"}
                </a>
              </li>
            </div>
          </ul>
        </UserProfile>
        {/* <UserProfile onMouseLeave={() => setShowProfile(false)}
          onMouseEnter={() => setShowProfile(true)} >
          {showProfile &&
            <ul
              style={profilePopupStyle}
              onMouseLeave={() => setShowProfile(false)}
              onMouseEnter={() => setShowProfile(true)}
              className="p-20 shadow rounded">
              <div className="d-flex flex-column align-items-start ">
                <li style={{ cursor: "default" }} >
                  <a style={{ fontWeight: "bold", fontSize: "16px", color: popUpTextStyle, cursor: "default" }} href="#">{sessionStorage.getItem("name")?.toUpperCase()}</a>
                </li>
                <li style={{ cursor: "default" }} className="pt-0 ">
                  <a style={{ color: popUpTextStyle, cursor: "default" }} href="#">{sessionStorage.getItem("email")?.toUpperCase()}</a>
                </li>
                <li style={{ cursor: "default" }} className="pt-0 mb-2">
                  <a style={{ color: popUpTextStyle, cursor: "default" }} href="#">{sessionStorage.getItem("role")?.toUpperCase()}</a>
                </li>
                <hr style={{ width: "100%", margin: "4px 0px", borderColor: dividerStyle, opacity: "0.2" }}></hr>
                <li className="logOutIcon" style={{ width: "inherit", }}>
                  <a
                    className="d-flex align-items-center logOutIcon"
                    onClick={logoutApp}
                  >
                    <LogOut className="mt-0 mr-2 " /> {"Log out"}
                  </a>
                </li>
              </div>

            </ul>
          }

        </UserProfile> */}
      </li>
    </Fragment>
  );
};

export default withRouter(UserMenu);
