import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import UserPanel from "./userPanel";
import { MENUITEMS } from "./menu";
import { Link, NavLink } from "react-router-dom";
import { translate } from "react-switch-lang";
import configDB from "../../../data/customizer/config";
import "./style.css";
import SearchHeader from "../header-component/searchHeader";
import Config from "../../../config/index";

const Sidebar = (props) => {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const [margin, setMargin] = useState(0);
  const [iconWidth, seticonWidth] = useState(20);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  // const [features, setFeatures] = useState(MENUITEMS);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [activeParent, setActiveParent] = useState("");
  const [activeChild, setActiveChild] = useState("");
  const [checkVisible, setCheckVisible] = useState("")
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const layout = useSelector((content) => content.Customizer.layout);
  // Selector STARTS
  const features = useSelector((data) => data.Features).features;
  // Selector ENDS
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    var currentUrl = window.location.pathname;

    features?.length > 0 &&
      features.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;

        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;

          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });

    const timeout = setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = elmnt.offsetWidth;
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
        seticonWidth(60);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
        seticonWidth(20);
      }
    }, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setActiveChild(sessionStorage.getItem("Active_Child"));
    if (window.location.href?.includes("/dashboard/default")) {
      setActiveParent("Dashboard");
    } else if (
      window.location.href?.includes("/company-listing") ||
      window.location.href?.includes("/company-counter") ||
      window.location.href?.includes("/company-dashboard") ||
      window.location.href?.includes("/company-requests")
    ) {
      setActiveParent("Company");
    } else if (
      window.location.href?.includes("/investors-listing") ||
      window.location.href?.includes("/investor-request-listing")
    ) {
      setActiveParent("Investors");
    } else if (
      window.location.href?.includes("/shareholder-listing") ||
      window.location.href?.includes("/share-certificate-listing") ||
      window.location.href?.includes("/physical-file-uploader") ||
      window.location.href?.includes("/certificate-file-uploader") ||
      window.location.href?.includes("/shareholding-uploader")
    ) {
      setActiveParent("Shareholding");
    } else if (
      window.location.href?.includes("/event-calendar") ||
      window.location.href?.includes("/statutory-requirements-listing") ||
      window.location.href?.includes("/statutory-events")
    ) {
      setActiveParent("Statutory");
    } else if (
      window.location.href?.includes("/evoting-dashboard") ||
      window.location.href?.includes("/elections-of-directors-listing") ||
      window.location.href?.includes("/candidates-listing") ||
      window.location.href?.includes("/election-voting") ||
      window.location.href?.includes("/election-voting-report") ||
      window.location.href?.includes("/compile-elections-results") ||
      window.location.href?.includes("/proxy-listing") ||
      window.location.href?.includes("/authorization-listing") ||
      window.location.href?.includes("/special-resolution-listing") ||
      window.location.href?.includes("/special-voting") ||
      window.location.href?.includes("/special-voting-report") ||
      window.location.href?.includes("/compile-resolution-results") ||
      window.location.href?.includes("/evoting-intimation-letters") ||
      window.location.href?.includes("/voting-attendance")
    ) {
      setActiveParent("Election");
    } else if (
      window.location.href?.includes("/inprocess-transactions") ||
      window.location.href?.includes("/transaction-listing")
    ) {
      setActiveParent("Transactions");
    } else if (
      window.location.href?.includes("/disbursement-listing") ||
      window.location.href?.includes("/disbursement-uploader") ||
      window.location.href?.includes("/dividend-payment") ||
      window.location.href?.includes("/dividend-disbursement-recon") ||
      window.location.href?.includes("/processed-dividend-disbursements")
    ) {
      setActiveParent("Dividend  Disbursement");
    } else if (
      window.location.href?.includes("/corporate-action-calculator") ||
      window.location.href?.includes("/corporate-announcement-listing") ||
      window.location.href?.includes("/corporate-entitlement-listing") ||
      window.location.href?.includes("/corporate-entitlement-uploader") ||
      window.location.href?.includes("/bank-deposit-uploader") ||
      window.location.href?.includes("/intimation-letter") ||
      window.location.href?.includes("/right-credit")
    ) {
      setActiveParent("Corporate Actions");
    } else if (
      window.location.href?.includes("/public-offering-dashboard") ||
      window.location.href?.includes("/ipo-offering") ||
      window.location.href?.includes("/ipo-subscription-uploader") ||
      window.location.href?.includes("/ipo-subscriptions") ||
      window.location.href?.includes("/ipo-payment-reconciliation") ||
      window.location.href?.includes("/ipo-failed-payments") ||
      window.location.href?.includes("/ipo-subscription-report") ||
      window.location.href?.includes("/ipo-allotment") ||
      window.location.href?.includes("/ipo-allotment-report")
    ) {
      setActiveParent("Public Offerings");
    } else if (
      window.location.href?.includes("/shareholding-history") ||
      window.location.href?.includes("/shareholding-pattern") ||
      window.location.href?.includes("/category-of-shareholding") ||
      window.location.href?.includes("/list-of-shareholders") ||
      window.location.href?.includes("/shareholding-statement") ||
      window.location.href?.includes("/free-float-report") ||
      window.location.href?.includes("/right-allotment-report") ||
      window.location.href?.includes("/bonus-allotment-report") ||
      window.location.href?.includes("/dividend-allotment-report") ||
      window.location.href?.includes("/investors-request-report") ||
      window.location.href?.includes("/tos-report") ||
      window.location.href?.includes("/label-printing")
    ) {
      setActiveParent("Reporting");
    } else if (
      window.location.href?.includes("/roles-listing") ||
      window.location.href?.includes("/users-listing") ||
      window.location.href?.includes("/atl-listing")
    ) {
      setActiveParent("Management");
    } else {
      setActiveParent("");
    }
  }, [window.location.href]);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    features &&
      features.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
    item.active = !item.active;
    setMainMenu({ mainmenu: features });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    setActiveParent("");
    if (localStorage.getItem("wrapper") === "horizontal_sidebar") {
      features.forEach((a) => {
        if (a.label == item.label) {
          setActiveParent(a.label);
        }
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = true;
            setActiveParent(a.label);
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = true;
              setActiveParent(a.label);
            }
          });
        });
      });
    }
    if (!item.active) {
      features.forEach((a) => {
        if (features.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: features });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  const closeSidebar = () => {
    if (document.documentElement.clientWidth <= 768) {
      document.querySelector(".page-main-header").classList.toggle("open");
      document.querySelector(".page-sidebar").classList.toggle("open");
    }
  };

  const layoutWrapper = localStorage.getItem("wrapper");
  const searchStyleText =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "hsla(0,0%,100%,.85)";
  const activeParentStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "black"
      : "#ffffff";
  // const activeParentStyle =
  //   mixLayout == "light-only" || mixLayout == "dark-body-only"
  //     ? "1px solid black"
  //     : "1px solid #ffffff";
  const dividerForSelectedItems = {
    margin: "2px",
    borderColor: activeParentStyle,
    position: "absolute",
    width: "67%",
    left: "46%",
    top: "50%",
    transform: "translate(-50%, 10px)",
  };
  // const TestIf = () => {
  //   setCheckVisible(false)
  //   // debugger
  //   let element = document.getElementById("Hover-Element").style.display != "none";
  //   element && setCheckVisible(true)
  // }
  // useEffect(() => {
  //   document.getElementById("Hover-Element").style.display === "none" && setCheckVisible(false)
  // }, [document.getElementById("Hover-Element")])

  return (
    <Fragment>
      <div
        className={
          layoutWrapper === "compact-page"
            ? "page-sidebar WrapperHoverSidebar"
            : "page-sidebar"
        }
      >
        <div className="">
          <div className="justify-content-center">
            <NavLink
              to={`${process.env.PUBLIC_URL}/dashboard/default`}
              className="mt-4"
            >
              {/* <img className="blur-up lazyloaded" src={logo_compact} alt="" />
                            <img className="blur-up lazyloaded" src={logo} alt="" /> */}
              {/* <img className="blur-up lazyloaded" width="100" src={logo} alt="" /> */}
              {/* <center className="text-center">
                <img
                  id="sidebar-image"
                  src={logo}
                  width="100"
                  alt=""
                  className="my-2"
                />
              </center> */}
            </NavLink>
          </div>
        </div>
        {localStorage.getItem("wrapper") === "horizontal_sidebar" && (
          <SearchHeader />
        )}
        <div
          style={{
            height: layoutWrapper === "compact-wrapper" ? "100vh" : "",
          }}
          className="sidebar custom-scrollbar"
        >
          <UserPanel />
          {localStorage.getItem("wrapper") !== "horizontal_sidebar" && (
            <SearchHeader />
          )}

          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${layout === "rtl"
                ? hideLeftArrowRTL
                  ? "d-none"
                  : ""
                : hideLeftArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {features?.length > 0 &&
              features.map((menuItem, i) => (
                <li
                  onMouseEnter={() => {
                    (localStorage.getItem("wrapper") == "horizontal_sidebar" || localStorage.getItem("wrapper") == "compact-wrapper") && setCheckVisible(menuItem.label)
                  }}
                  onMouseLeave={() => {
                    (localStorage.getItem("wrapper") == "horizontal_sidebar" || localStorage.getItem("wrapper") == "compact-wrapper") && setCheckVisible("")
                  }}
                  id={
                    layoutWrapper === "compact-wrapper" ? "PaddingRemove" : ""
                  }
                  className={`${menuItem.active ? "active" : ""}`}
                  key={i}
                >
                  {menuItem.sidebartitle ? (
                    <div className="sidebar-title">{menuItem.sidebartitle}</div>
                  ) : (
                    ""
                  )}
                  {menuItem.type === "sub" ? (
                    <div

                      data-testid={menuItem.title}
                      className="sidebar-header"
                      onClick={() => {
                        features.forEach((a) => {
                          a.children.forEach((b) => {
                            b.active = false;
                            b.children.forEach((c) => {
                              c.active = false;
                            });
                          });
                        });
                        toggletNavActive(menuItem);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={
                          layoutWrapper === "compact-wrapper"
                            ? menuItem.classname.replaceAll('"', "") + " ml-3"
                            : menuItem.classname.replaceAll('"', "")
                        }
                      ></i>
                      <span
                        className="position-relative"
                      >
                        {menuItem.title}
                        {(layoutWrapper === "compact-wrapper" ||
                          layoutWrapper === "horizontal_sidebar") && (
                            <i
                              style={{
                                fontSize: "17px",
                                marginTop:
                                  layoutWrapper === "horizontal_sidebar" && "2px",
                              }}
                              className={`${(localStorage.getItem("wrapper") !==
                                "horizontal_sidebar" && menuItem.active) || (checkVisible == menuItem.label)
                                ? "fa fa-angle-down pull-right"
                                : "fa fa-angle-right pull-right"
                                }`}
                            ></i>
                          )}
                        {((localStorage.getItem("wrapper") !==
                          "horizontal_sidebar" &&
                          menuItem.active) ||
                          (activeParent == menuItem.label)) && (
                            <hr style={dividerForSelectedItems}></hr>
                          )}
                      </span>
                      {(layoutWrapper === "default" ||
                        layoutWrapper === "compact-page") &&
                        menuItem.title !== "Dashboard" && (
                          <i
                            className={`${localStorage.getItem("wrapper") !==
                              "horizontal_sidebar" && menuItem.active
                              ? "fa fa-angle-down pull-right"
                              : "fa fa-angle-right pull-right"
                              }`}
                          ></i>
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                  {menuItem.type === "link" ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                      className={`sidebar-header ${menuItem.active ? "active" : ""
                        }`}
                      onClick={() => {
                        closeSidebar();
                        toggletNavActive(menuItem);
                      }}
                    >
                      <i
                        className={
                          layoutWrapper === "compact-page"
                            ? menuItem.classname + " test"
                            : layoutWrapper === "compact-wrapper"
                              ? menuItem.classname + " HomeIcon"
                              : menuItem.classname
                        }
                      ></i>
                      <span
                        style={{
                          position: "relative",
                        }}
                        className={
                          layoutWrapper === "compact-page" ? "test" : ""
                        }
                      >
                        {props.t(menuItem.title)}
                        {((localStorage.getItem("wrapper") !==
                          "horizontal_sidebar" &&
                          menuItem.active) ||
                          activeParent == menuItem.label) && (
                            <hr style={dividerForSelectedItems}></hr>
                          )}
                      </span>
                      {menuItem.children.length > 0 ? (
                        <i className="fa fa-angle-right pull-right"></i>
                      ) : (
                        ""
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  {menuItem?.children?.length > 0 && menuItem.children ? (
                    <ul
                      onMouseEnter={() => {
                        (localStorage.getItem("wrapper") == "horizontal_sidebar") && setCheckVisible(menuItem.label)
                      }}
                      onMouseLeave={() => {
                        (localStorage.getItem("wrapper") == "horizontal_sidebar") && setCheckVisible("")
                      }}
                      className={`sidebar-submenu${menuItem.active ? " menu-open" : ""
                        }`}
                      style={
                        menuItem.active
                          ? { opacity: 1, transition: "opacity 500ms ease-in" }
                          : {}
                      }
                    >
                      {menuItem.children.map((childrenItem, index) => (
                        <li
                          key={index}
                          className={
                            childrenItem.children
                              ? childrenItem.active
                                ? "active"
                                : ""
                              : ""
                          }
                        >
                          {childrenItem.type === "sub" ? (
                            <a
                              href="#javascript"
                              onClick={() => toggletNavActive(childrenItem)}
                            >
                              <i className="fa fa-circle"></i>
                              {props.t(childrenItem.title)}
                              <i className="fa fa-angle-right pull-right"></i>
                            </a>
                          ) : (
                            ""
                          )}

                          {childrenItem.type === "link" ? (
                            <Link
                              id="Hover-Element"
                              to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                              className={
                                childrenItem.active ||
                                  activeChild == childrenItem?.label
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                sessionStorage.setItem(
                                  "Active_Child",
                                  childrenItem.label
                                );
                                closeSidebar();
                                toggletNavActive(childrenItem);
                              }}
                            >
                              <i style={{ color: (activeChild && localStorage.getItem("wrapper") == "horizontal_sidebar") == childrenItem?.label ? "black" : "" }} className="fa fa-circle"></i>
                              {localStorage.getItem("wrapper") !== "horizontal_sidebar" && props.t(childrenItem.title)}
                              {localStorage.getItem("wrapper") == "horizontal_sidebar" && <span style={{ color: activeChild == childrenItem?.label ? "black" : "" }}>{props.t(childrenItem.title)}</span>}
                            </Link>
                          ) : (
                            ""
                          )}
                          {childrenItem?.children?.length > 0 &&
                            childrenItem.children ? (
                            <ul
                              className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"
                                }`}
                            >
                              {childrenItem.children.map(
                                (childrenSubItem, key) => (
                                  <li
                                    className={
                                      childrenSubItem.active ? "active" : ""
                                    }
                                    key={key}
                                  >
                                    {childrenSubItem.type === "link" ? (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                        className={
                                          childrenSubItem.active ? "active" : ""
                                        }
                                        onClick={() =>
                                          toggletNavActive(childrenSubItem)
                                        }
                                      >
                                        <i className="fa fa-circle"></i>
                                        {props.t(childrenSubItem.title)}
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            <li
              className={`right-arrow ${layout === "rtl"
                ? hideRightArrowRTL
                  ? "d-none"
                  : ""
                : hideRightArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
          {localStorage.getItem("wrapper") === "compact-wrapper" &&
            <div
              className={"border-top pt-5 pb-2 d-flex justify-content-center"}
            >
              <p style={{ color: searchStyleText }} className="mb-0 pl-3">
                {"Digital Custodian"}
                <i className="fa fa-heart ml-3"></i> {Config.appVersion}
              </p>
            </div>}
        </div>
        <div
          className={
            localStorage.getItem("wrapper") === "horizontal_sidebar"
              ? "py-2 border-top col-md-12 d-flex justify-content-center"
              : "pt-4 border-top col-md-12 d-flex justify-content-center"
          }
        >
          <p style={{ color: searchStyleText }} className="mb-0">
            {"Digital Custodian"}
            <i className="fa fa-heart ml-2"></i> {Config.appVersion}
          </p>
        </div>
      </div>
    </Fragment>
  );
};
export default translate(Sidebar);

// export default translate(Sidebar);
