// change with your own variables
//// Migration DEV
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry DEV",
//   REACT_APP_VERSION: "v2.6.21",
//   REACT_APP_BASE_URL: "https://sr-dev.dccl.com.pk:3000/api",
//   REACT_APP_SOCKET_URL: "https://sr-dev.dccl.com.pk:3000/",
//   REACT_APP_IMAGE_URL: "https://sr-dev.dccl.com.pk:3000/",
//   REACT_APP_AUTH_URL: "https://dccl.pk:3011/",
//   REACT_APP_BACKEND_URL: "https://sr-dev.dccl.com.pk:3000/",
//   REACT_APP_TRUSTEE_HBL_COUNTERS_URL: "https://digitalcustodian.co:3000/api/",
//   REACT_APP_HBL_API_URL: "https://api.hbl.com:8343/CorpPay/Transaction/Process",
//   REACT_APP_HBL_API_TOKEN:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDRENfSEJMQXNzZXQifQ.n2LscGwtvBi6tjg2XeTvL1NRqA31oo7Qw5XqkRynq7I",
//   REACT_APP_HBL_API_AUTHORIZATION:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDUlBMIn0.zlPxtpikkzxQqfUgpb0rdCFrc-E5bifMa83JkSj7FF8",
// };

//// New UAT
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry UAT",
//   REACT_APP_VERSION: "v2.6.21",
//   REACT_APP_BASE_URL: "http://203.128.8.2:3224/api",
//   REACT_APP_SOCKET_URL: "http://203.128.8.2:3224/",
//   REACT_APP_IMAGE_URL: "http://203.128.8.2:3224/",
//   REACT_APP_AUTH_URL: "http://203.128.8.2:4011/",
//   REACT_APP_BACKEND_URL: "http://203.128.8.2:3224/",
//   REACT_APP_TRUSTEE_HBL_COUNTERS_URL: "https://digitalcustodian.co:3000/api/",
//   REACT_APP_HBL_API_URL: "https://api.hbl.com:8343/CorpPay/Transaction/Process",
//   REACT_APP_HBL_API_TOKEN:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDRENfSEJMQXNzZXQifQ.n2LscGwtvBi6tjg2XeTvL1NRqA31oo7Qw5XqkRynq7I",
//   REACT_APP_HBL_API_AUTHORIZATION:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDUlBMIn0.zlPxtpikkzxQqfUgpb0rdCFrc-E5bifMa83JkSj7FF8",
// };

// Eth Production USE
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry PROD",
//   REACT_APP_VERSION: "v2.5.20",
//   REACT_APP_BASE_URL: "https://dr.dccl.com.pk:3012/api",
//   REACT_APP_SOCKET_URL: "https://dr.dccl.com.pk:3012/",
//   REACT_APP_IMAGE_URL: "https://dr.dccl.com.pk:3012/",
//   REACT_APP_AUTH_URL: "https://digitalcustodian.co:3011/",
//   REACT_APP_TRUSTEE_HBL_COUNTERS_URL: "https://digitalcustodian.co:3000/api/",
//   REACT_APP_HBL_API_URL: "https://api.hbl.com:8343/CorpPay/Transaction/Process",
//   REACT_APP_HBL_API_TOKEN:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDRENfSEJMQXNzZXQifQ.n2LscGwtvBi6tjg2XeTvL1NRqA31oo7Qw5XqkRynq7I",
//   REACT_APP_HBL_API_AUTHORIZATION:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDUlBMIn0.zlPxtpikkzxQqfUgpb0rdCFrc-E5bifMa83JkSj7FF8",
// };

// Eth Production Backup USE
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry PROD",
//   REACT_APP_VERSION: "v2.5.20",
//   REACT_APP_BASE_URL: "https://dr-prod.dccl.tech:3004/api",
//   REACT_APP_SOCKET_URL: "https://dr-prod.dccl.tech:3004/",
//   REACT_APP_IMAGE_URL: "https://dr-prod.dccl.tech:3004/",
//   REACT_APP_AUTH_URL: "https://digitalcustodian.co:3011/",
//   REACT_APP_TRUSTEE_HBL_COUNTERS_URL: "https://digitalcustodian.co:3000/api/",
//   REACT_APP_HBL_API_URL: "https://api.hbl.com:8343/CorpPay/Transaction/Process",
//   REACT_APP_HBL_API_TOKEN:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDRENfSEJMQXNzZXQifQ.n2LscGwtvBi6tjg2XeTvL1NRqA31oo7Qw5XqkRynq7I",
//   REACT_APP_HBL_API_AUTHORIZATION:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJDUlBMIn0.zlPxtpikkzxQqfUgpb0rdCFrc-E5bifMa83JkSj7FF8",
// };

// TEST
export const env = {
  REACT_APP_TITLE: JSON.parse(sessionStorage.getItem("env")).REACT_APP_TITLE,
  REACT_APP_VERSION: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_VERSION,
  REACT_APP_BASE_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_BASE_URL,
  REACT_APP_SOCKET_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_SOCKET_URL,
  REACT_APP_IMAGE_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_IMAGE_URL,
  REACT_APP_AUTH_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_AUTH_URL,
  REACT_APP_BACKEND_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_BACKEND_URL,
  REACT_APP_HBL_API_AUTHORIZATION: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_HBL_API_AUTHORIZATION,
  REACT_APP_TRUSTEE_HBL_COUNTERS_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_TRUSTEE_HBL_COUNTERS_URL,
  REACT_APP_HBL_API_URL: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_HBL_API_URL,
  REACT_APP_HBL_API_TOKEN: JSON.parse(sessionStorage.getItem("env"))
    .REACT_APP_HBL_API_TOKEN,
};
