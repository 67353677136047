import Spinner from "components/common/spinner";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getTransactionTypes } from "store/services/transaction.service";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useSelector } from "react-redux";

const CheckListContent = ({
  transactionRequest,
  acceptedLoading,
  rejectedLoading,
  updateAcceptStatusAccepted,
  updateAcceptStatusRejected,
}) => {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const onHoverClass = (mixLayout === "dark-body-only" || mixLayout === "dark-only" || mixLayout === "dark-sidebar-body-mix") ? "onHover-dark" : "onHover"
  const [checkList, setCheckList] = useState([]);
  const [checkListLoading, setCheckListLoading] = useState(false);
  useEffect(() => {
    const getAllTransactionRequest = async () => {
      try {
        setCheckListLoading(true);
        const baseEmail = sessionStorage.getItem("email") || "";
        const response = await getTransactionTypes(baseEmail);
        if (response.status === 200) {
          setCheckList(
            response.data.data.find(
              (type) =>
                type.transactionName === transactionRequest.txn_type
            ).checklist
          );
        }
        setCheckListLoading(false);
      } catch (error) {
        !!error?.response?.data?.message
          ? toast.error(error?.response?.data?.message)
          : toast.error("Transaction Types Not Found");
        setCheckListLoading(false);
      }
    };
    getAllTransactionRequest();
  }, []);

  const tableWrapperRef = React.useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (tableWrapperRef.current) {
        const tableWidth = tableWrapperRef.current.scrollWidth;
        const wrapperWidth = tableWrapperRef.current.clientWidth;
        setIsScrollable(tableWidth > wrapperWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="row">
        {!checkListLoading && checkList.length > 0 ? (
          <TableStyle>
            <div
              style={{
                position: "relative"
              }}
              className="px-3 pb-3"
            >
              {isScrollable &&
                <ScrollIconStyle>
                  <FaChevronLeft
                    style={{
                      position: "absolute",
                      left: "1px",
                      bottom: "11px",
                      color: "grey",
                      cursor: "pointer"
                    }}
                    className="left_Icon"
                  />
                </ScrollIconStyle>}
              <div ref={tableWrapperRef} className="table-responsive">
                <table className="table table-striped  ">
                  <thead>
                    <tr>
                      <th>Check List Item No.</th>
                      <th>Check List Item Description</th>
                      <th>Checked</th>
                    </tr>
                  </thead>

                  <tbody>
                    {checkList.length !== 0 &&
                      checkList.map((item, i) => (
                        <tr className={onHoverClass} key={i}>
                          <td>{item.checklistItemNo}</td>
                          <td>
                            <label className="d-block" htmlFor="chk-ani">
                              <input
                                className="checkbox_animated"
                                id="chk-ani"
                                type="checkbox"
                                defaultChecked={item.checked}
                                onChange={(e) => (item.checked = e.target.checked)}
                              />
                              Approve
                            </label>
                          </td>
                          <td>{item.checklistItemDescription}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {isScrollable &&
                <ScrollIconStyle>
                  <FaChevronRight
                    style={{
                      position: "absolute",
                      right: "1px",
                      bottom: "11px",
                      color: "grey",
                      cursor: "pointer"
                    }}
                    className="right_Icon"
                  />
                </ScrollIconStyle>}
            </div>
          </TableStyle>
        ) : (
          <Spinner />
        )}
      </div>
      <div className="row px-2">
        {JSON.parse(transactionRequest?.txn_flow)
          .find((item) => item.pointedTo === true)
          ?.labels?.map((tem, i) => (
            <button
              key={i}
              className={`btn btn-${i === 0 ? "primary" : "danger"} mx-2`}
              disabled={acceptedLoading || rejectedLoading}
              onClick={(e) =>
                i === 0
                  ? updateAcceptStatusAccepted(checkList)
                  : updateAcceptStatusRejected(checkList)
              }
            >
              {i === 0 && acceptedLoading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : i === 1 && rejectedLoading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{tem}</span>
              )}
            </button>
          ))}
        {/* <button
              className="btn btn-primary mx-2"
              disabled={Boolean(loading)}
              onClick={(e) => updateAcceptStatusAccepted()}
            >
              {loading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{"Accept"}</span>
              )}
            </button> */}
        {/* <button
              className="btn btn-danger mx-2"
              disabled={Boolean(loading)}
              onClick={(e) => updateAcceptStatusRejected()}
            >
              {loading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{"Reject"}</span>
              )}
            </button> */}
      </div>
    </>
  );
};

export default CheckListContent;
const ScrollIconStyle = styled.div`
.left_Icon:hover{
  background-color:rgba(0,0,0,0.1)
}
.right_Icon:hover{
  background-color:rgba(0,0,0,0.1)
}
`
const TableStyle = styled.div`
.onHover:hover{
  background-color:#ecf3f8 !important
}
  .onHover-dark:hover{
  background-color:#313131 !important
  }

`;