import React, { useEffect, useState } from "react";
import Config from "../../config/index";
import { handleResponse } from "services/fack.backend";

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  const [isSidebarOpen, setIsSidebarOpen] = useState("")

  useEffect(() => {
    const sidebarElement = document.querySelector(".page-sidebar");
    const handleClassChange = () => {
      setIsSidebarOpen(!sidebarElement.classList.contains("open"));
    };

    sidebarElement.addEventListener("transitionend", handleClassChange);
    handleClassChange();

    return () => {
      sidebarElement.removeEventListener("transitionend", handleClassChange);
    };
  }, []);
  return (
    <footer
      style={{
        marginLeft: isSidebarOpen ? "" : "0px"
      }}
      className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">{`Copyright ${currentYear} © Share Registrar All rights reserved.`}</p>
          </div>
          <div className="col-md-6">
            <p className="pull-right mb-0">
              {"Digital Custodian"}
              <i className="fa fa-heart"></i> {Config.appVersion}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
