// Error Styles
export const errorStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid red",
  }),
};
export const disabledStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#E9ECEF",
  }),
};

export const disabledStylesForms = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#E9ECEF",
    border:"1px solid #ced4da",
    borderRadius: "0.25rem"
  }),
};


export const darkStyle = {
  control: (base, state) => ({
    ...base,
    // backgroundColor: "#293240",
    backgroundColor: "white",
    color: "#333",
    border: "1px solid #374558",
  }),

  input: (base, state) => ({
    ...base,
    color: "#333",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "#333",
  }),
  menu: (base, state) => ({
    ...base,
    color: "#333",
  }),
};

export const darkStyle2 = {
  control: (base, state) => ({
    ...base,
    // backgroundColor: "#293240",
    backgroundColor: "white",
    color: "#333",
    border: "1px solid #374558",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),

  input: (base, state) => ({
    ...base,
    color: "#333",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "#333",
  }),
  menu: (base, state) => ({
    ...base,
    color: "#333",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    alignSelf: "center",
    marginLeft: "auto",
    transform: "translateY(-10%)",
  }),
  clearIndicator: (base, state) => ({
    transform: "translateY(-5%)",
  }),
};
