import axios from "axios";
import Config from "../../config";
import RefreshTokenHandler from "./refresh-token";

const getDisburse = async (email) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividenddisbursements?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDisburse(email);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getPaginatedDisbursementsByCompanyCode = async (
  email,
  company_code,
  page_number,
  value,
  search_criteria
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividenddisbursements/paginate?email=${email}&company_code=${company_code}&page_size=50&page_number=${page_number}&value=${value}&search_criteria=${search_criteria}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDisburse(email);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const addDisburse = async (
  email,
  disburse_date,
  folio_no,
  amount_disbursed,
  status
) => {
  try {
    const url = `${Config.baseUrl}/dividenddisbursements/`;

    const result = await axios.post(
      url,
      {
        email,
        // disburse_id,
        disburse_date,
        folio_no,
        amount_disbursed,
        status,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );

    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addDisburse(
          email,
          disburse_date,
          folio_no,
          amount_disbursed,
          status
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const updateDisburse = async (
  email,
  disburse_id,
  disburse_date,
  folio_no,
  amount_disbursed,
  status
) => {
  try {
    const url = `${Config.baseUrl}/dividenddisbursements/update`;

    const result = await axios.post(
      url,
      {
        email,
        disburse_id,
        disburse_date,
        folio_no,
        amount_disbursed,
        status,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateDisburse(
          email,
          disburse_id,
          disburse_date,
          folio_no,
          amount_disbursed,
          status
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getDisburseCount = async (email) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dashboard/dividenddisbursement-counter?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDisburse(email);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const disburseBulkUpload = async (
  email,
  company_code,
  data,
  dividend_number,
  overwrite_existing,
  sender_account
) => {
  try {
    const url = `${Config.baseUrl}/dividendpayments/bulk-upload-dividend-payments`;
    const result = await axios.post(
      url,
      {
        email,
        company_code,
        data,
        dividend_number,
        overwrite_existing,
        sender_account,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await disburseBulkUpload(email, company_code, data);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getDividendpayments = async (email, company_code, dividend_number) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividendpayments/by-company-and-dividend-issue-number-unpaid?email=${email}&company_code=${company_code}&dividend_number=${dividend_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDividendpayments(email, company_code, dividend_number);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getDividendPaymentsPaid = async (
  email,
  company_code,
  dividend_number
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividendpayments/by-company-and-dividend-issue-number-paid?email=${email}&company_code=${company_code}&dividend_number=${dividend_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDividendpayments(email, company_code, dividend_number);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getDividendReconPayments = async (
  email,
  company_code,
  dividend_number
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividendpayments/payments-recon?email=${email}&company_code=${company_code}&dividend_number=${dividend_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDividendpayments(email, company_code, dividend_number);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getDividentNumber = async (email, company_code) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/dividendpayments/dividend-issue-numbers-by-company?email=${email}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDividentNumber(email, company_code);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const updateDividendPaymentRecord = async (
  email,
  dividend_payment_id,
  company_code,
  date,
  folio_no,
  iban,
  account_no,
  bank_code,
  bank_name,
  branch_name,
  branch_code,
  branch_address,
  address,
  amount,
  payment_status,
  payment_date,
  paid,
  status,
  entitlement_id,
  announcement_id,
  transaction_id,
  successful_payment_reference_id,
  dividend_period,
  transfer_no,
  execution_date,
  remarks,
  account_title,
  father_husband_name,
  cnic,
  ntn,
  passport_no,
  shareholder_name,
  shareholder_mobile,
  shareholder_email,
  registration,
  shareholder_type,
  shareholder_gender,
  shareholder_dob,
  cds_participant,
  cds_account,
  reference_id,
  reason,
  company_dividend_id,
  dividend_number,
  manual_recon
) => {
  try {
    const url = `${Config.baseUrl}/dividendpayments/update`;

    const result = await axios.post(
      url,
      {
        email,
        dividend_payment_id,
        company_code,
        date,
        folio_no,
        iban,
        account_no,
        bank_code,
        bank_name,
        branch_name,
        branch_code,
        branch_address,
        address,
        amount,
        payment_status,
        payment_date,
        paid,
        status,
        entitlement_id,
        announcement_id,
        transaction_id,
        successful_payment_reference_id,
        dividend_period,
        transfer_no,
        execution_date,
        remarks,
        account_title,
        father_husband_name,
        cnic,
        ntn,
        passport_no,
        shareholder_name,
        shareholder_mobile,
        shareholder_email,
        registration,
        shareholder_type,
        shareholder_gender,
        shareholder_dob,
        cds_participant,
        cds_account,
        reference_id,
        reason,
        company_dividend_id,
        dividend_number,
        manual_recon,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateDisburse(
          email,
          dividend_payment_id,
          company_code,
          date,
          folio_no,
          iban,
          account_no,
          bank_code,
          bank_name,
          branch_name,
          branch_code,
          branch_address,
          address,
          amount,
          payment_status,
          payment_date,
          paid,
          status,
          entitlement_id,
          announcement_id,
          transaction_id,
          successful_payment_reference_id,
          dividend_period,
          transfer_no,
          execution_date,
          remarks,
          account_title,
          father_husband_name,
          cnic,
          ntn,
          passport_no,
          shareholder_name,
          shareholder_mobile,
          shareholder_email,
          registration,
          shareholder_type,
          shareholder_gender,
          shareholder_dob,
          cds_participant,
          cds_account,
          reference_id,
          reason,
          company_dividend_id,
          dividend_number,
          manual_recon
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const startDividendPaymentProcessing = async (
  email,
  dividend_payment_id,
  company_code
) => {
  try {
    const url = `${Config.baseUrl}/dividendpayments/start-payment-processing`;

    const result = await axios.post(
      url,
      {
        email,
        dividend_payment_id,
        company_code,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateDisburse(email, dividend_payment_id, company_code);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const markPaymentAsFailed = async (
  email,
  dividend_payment_id,
  company_code,
  reason,
  remarks //rendering in table
) => {
  try {
    const url = `${Config.baseUrl}/dividendpayments/payment-failed`;

    const result = await axios.post(
      url,
      {
        email,
        dividend_payment_id,
        company_code,
        reason,
        remarks,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await markPaymentAsFailed(
          email,
          dividend_payment_id,
          company_code,
          reason,
          remarks
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const markPaymentAsPaid = async (
  email,
  dividend_payment_id,
  company_code,
  manual_recon,
  payment_date,
  reason,
  paid,
  reference_id,
  transaction_id,
  successful_payment_reference_id,
  transfer_no,
  execution_date,
  remarks //rendering in table
) => {
  try {
    const url = `${Config.baseUrl}/dividendpayments/mark-payment`;

    const result = await axios.post(
      url,
      {
        email,
        dividend_payment_id,
        company_code,
        manual_recon,
        payment_date,
        reason,
        paid,
        reference_id,
        transaction_id,
        successful_payment_reference_id,
        transfer_no,
        execution_date,
        remarks, //rendering in table
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await markPaymentAsPaid(
          email,
          dividend_payment_id,
          company_code,
          manual_recon,
          payment_date,
          reason,
          paid,
          reference_id,
          transaction_id,
          successful_payment_reference_id,
          transfer_no,
          execution_date,
          remarks //rendering in table
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export {
  getDisburse,
  addDisburse,
  updateDisburse,
  getDisburseCount,
  getPaginatedDisbursementsByCompanyCode,
  disburseBulkUpload,
  getDividentNumber,
  getDividendpayments,
  getDividendReconPayments,
  getDividendPaymentsPaid,
  updateDividendPaymentRecord,
  startDividendPaymentProcessing,
  markPaymentAsFailed,
  markPaymentAsPaid,
};
